import React from "react";
import {AutocompleteInterface} from "../../interfaces/Autocomplete";
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import NoImage from '../../assets/img/no-img_small.svg';
import {useAppSelector} from "../../hooks";

const AutocompleteResults: React.FC<{
    data: AutocompleteInterface,
    querySearch: string,
    closeActiveSearch: (data: boolean) => void,
    setShowResults: (data: boolean) => void
    }> = ({
                                         data,
                                         querySearch,
                                         closeActiveSearch,
                                         setShowResults
    }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currUser = useAppSelector((state) => state.user.value);

    const closeSearch = () => {
        closeActiveSearch(false)
        setShowResults(false)
    }

    return (
        <div>
            {!data.products.length ? <div className="autocompleteData empty">
                <h3>{t('autocomplete.notFound')}</h3>
            </div> : <div className="autocompleteData">
                <div className="autocompleteDataCategory">
                    <h3>{t('autocomplete.inCategory')}</h3>
                    <ul>
                        {data.categories.map((category, index) => {
                            return (
                                <li key={index}>
                                    <NavLink to={'/category/' + category.id} onClick={closeSearch} className={"categoryName"}>
                                        {category.name}
                                    </NavLink>
                                </li>
                            )
                        })}

                    </ul>
                </div>
                <div className="autocompleteDataProducts">
                    <ul>
                        {data.products.map((product, index) => {
                            return (
                                <li key={index}>
                                    <NavLink to={'/product/' + product.id} onClick={closeSearch} className={"productName"}>
                                        <img src={product.photo ? product.photo : NoImage} width={45} alt=""/>
                                        <div className="title">{product.name}</div>
                                        <div className="price">{product.price} {t(currUser.currency)}</div>
                                    </NavLink>
                                </li>
                            )
                        })}

                    </ul>
                </div>
                <div className="autocompleteDataResults">
                    <span className={"autocompleteDataAll"}>{t('autocomplete.found')} {data.totalProducts} {t('autocomplete.products')}</span>
                    <NavLink to={'/search?query=' + querySearch} onClick={closeSearch} className="autocompleteDataAll">{t('autocomplete.showAll')}</NavLink>
                    {/*<a href="src/components/Autocomplete/Autocomplete#" onClick={goToSearchPage} className={"autocompleteDataAll"}>{t('autocomplete.showAll')}</a>*/}
                </div>
            </div> }

        </div>
    )
}
export default AutocompleteResults
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, ButtonToolbar, Input, Message} from "rsuite";

import {useAppDispatch, useAppSelector} from "../../hooks";

import CartOrderProducts from "../CartOrderProducts/CartOrderProducts";
import ContactData from "../Form/ContactData/ContactData";
import OrderProductsShipping from "./OrderProductsShipping/OrderProductsShipping";

import {addOrder, removeOrder, updateOrder} from "../../slices/orderSlice";

import {PreOrderResponse} from "../../dto/order/PreOrderResponseWrapper";
import AddOrderModel from "../../dto/order/AddOrderModel";
import OrderRecipientList from "./OrderRecipientList/OrderRecipientList";
import User from "../../interfaces/User";
import {ShippingReceiver} from "../../interfaces/ShippingReceiver";
import PaymentAndPickupData from "../../interfaces/PaymentAndPickupData";
import ShippingAddress from "../../interfaces/ShippingAddress";

import './OrderProducts.sass'
import {removeFromCart, updateCount} from "../../slices/cartSlice";
import {updateCountReserve} from "../../slices/reserveSlice";
import {ValueType} from "rsuite/Checkbox";
import {CartOrder} from "../../interfaces/CartProduct";
import {updateProductCountAsync} from "../../slices/actions/cartAsyncActions";
import cartServices from "../../services/cartServices";
import {changeActiveOrder} from "../../slices/activeOrderSlice";



const OrderProducts: React.FC<{
    order: AddOrderModel,
    numberOrder: number,
    preOrderResult: PreOrderResponse | null,
    errorsStateShipping: boolean,
    errorStateReceiver: boolean,
    deleteErrorReceiver: Function,
    deleteErrorShipping: Function,
    isReserve: boolean}> = (
        {order,
            numberOrder,
            preOrderResult,
            errorsStateShipping,
            errorStateReceiver,
            deleteErrorReceiver,
            deleteErrorShipping,
            isReserve}) => {
    const {t} = useTranslation();
    const commentTxt = t('tableHeader.comment')
    const dispatch = useAppDispatch();

    const rate = useAppSelector<number>(state => state.rate.value)
    const user = useAppSelector<User>(state => state.user.value)
    const orderObjects = useAppSelector<AddOrderModel[]>(state => state.order.value)
    const cart = useAppSelector<CartOrder[]>(state => state.cart.value)
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderTotalUah, setOrderTotalUah] = useState(0);
    const [currOrderState, setCurrOrderState] = useState<AddOrderModel>(order)

    const contactDataTitle = t('order.contactData')



    useEffect(() => {
        setCurrOrderState(order);
    }, [order]);

    useEffect(() => {
        let totalPrice = 0

        currOrderState.items.forEach((product) => {
            totalPrice += product.product.price * product.count
        })
        setOrderTotal(totalPrice);
        let totalPriceCurrency = totalPrice * rate;
        setOrderTotalUah(totalPriceCurrency);
    }, [currOrderState]);

    useEffect(() => {
        const tmpOrder = orderObjects.find(o => o.cartOrderId == currOrderState.cartOrderId)
        if(tmpOrder) {
            setCurrOrderState(tmpOrder)
        }
    }, [orderObjects])

    const [toggleShowProducts, setToggleShowProducts] = useState(false)

    const isShowProducts = () => {
        setToggleShowProducts(isShowProducts => !isShowProducts)
    }

    const [toggleShippingRecipient, setToggleShippingRecipient] = useState(true)
    const shippingRecipient = (state: boolean) => {
        setToggleShippingRecipient(state);

    }

    /*const [toggleChooseRecipient, setToggleChooseRecipient] = useState(false)
    const chooseRecipient = () => {
        setToggleChooseRecipient(toggleChooseRecipient => !toggleChooseRecipient)
    }*/

    const [toggleAddComment, setToggleAddComment] = useState(false)
    const addComment = () => {
        setToggleAddComment(toggleAddComment => !toggleAddComment)
    }

    const setComment = (value: ValueType | undefined) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder};
            if (value){
                updatedOrder.comment = value as string
            }
            dispatch(updateOrder(updatedOrder))
        }


    }

    /*const [toggleAddRecipient, setToggleAddRecipient] = useState(false)
    const addRecipient = () => {
        setToggleAddRecipient(toggleAddRecipient => !toggleAddRecipient)
    }*/

    const [isCheckedOrder, setIsCheckedOrder] = useState(true)
    const changeOrderState = (value: number) => {
        !isCheckedOrder ? dispatch(addOrder(currOrderState)) : dispatch(removeOrder(value as number))
        setIsCheckedOrder( isCheckedOrder => !isCheckedOrder)

    }

    const addSavedAddress = (address: number) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);


        if(updatedOrder) {
            updatedOrder = {...updatedOrder}
            updatedOrder.isPickup = false;
            if (address) {
                updatedOrder.deliveryAddressId = address
                updatedOrder.deliveryAddress = null
            }
            dispatch(updateOrder(updatedOrder))
        }
        deleteErrorShipping(currOrderState.cartOrderId)
    }

    const chosenRecipient = (recipient: number) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder};
            if (recipient) {
                updatedOrder.receiverId = recipient
            }
            dispatch(updateOrder(updatedOrder))
        }
        deleteErrorReceiver(currOrderState.cartOrderId)
    }

    const editContactData = (name: string, email: string, phone: string) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder};
            updatedOrder.contactName = name
            updatedOrder.contactEmail = email
            updatedOrder.contactPhone = phone

            dispatch(updateOrder(updatedOrder))
        }
    }

    const addNewDeliveryData = (data: ShippingAddress) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder};
            updatedOrder.isPickup = false;
            updatedOrder.deliveryAddress = data
            updatedOrder.deliveryAddressId = null;

            dispatch(updateOrder(updatedOrder))
        }
        deleteErrorShipping(currOrderState.cartOrderId)
    }

    const [isAddNewReceiver, setIsAddNewReceiver] = useState(false)
    const addNewReceiverData = (data: ShippingReceiver) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder};
            updatedOrder.receiver = data
            updatedOrder.receiverId = null;


            dispatch(updateOrder(updatedOrder))
        }
        setToggleShippingRecipient(false)
        setIsAddNewReceiver(true)
        deleteErrorReceiver(currOrderState.cartOrderId)

    }

    //const [showDeliveryPayer, setShowDeliveryPayer] = useState<number | null>(null)
    //const [showOrderAmount, setShowOrderAmount] = useState<number | null>(null)
    const setPaymentAndPickupData = (data: PaymentAndPickupData) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder};
            if (data.deliveryPayer != null) {
                updatedOrder.deliveryPayer = data.deliveryPayer
                //setShowDeliveryPayer(data.deliveryPayer)
            }
            if (data.orderToPay) {
                updatedOrder.cashOnDeliveryAmount = +data.orderToPay
                //setShowOrderAmount(data.orderToPay)
            } else {
                updatedOrder.usePaymentControl = false;
            }

            if (data.carNumber) {
                updatedOrder.carNumber = data.carNumber
                //setShowOrderAmount(data.orderToPay)
            }

            if (data.carModel) {
                updatedOrder.carModel = data.carModel
                //setShowOrderAmount(data.orderToPay)
            }

            if (data.pickupDate) {
                updatedOrder.pickupDate = data.pickupDate
                //setShowOrderAmount(data.orderToPay)
            }

            if (data.pickupTime) {
                updatedOrder.pickupTime = data.pickupTime
                //setShowOrderAmount(data.orderToPay)
            }

            if(data.pickupDate || data.pickupTime || data.carNumber || data.carModel){
                updatedOrder.isPickup = true;
            }

            dispatch(updateOrder(updatedOrder))
        }
    }


    const chooseUserReceiver = () => {
        shippingRecipient(true)
        deleteErrorReceiver(currOrderState.cartOrderId)

        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder};
            //updatedOrder.receiverId = user.id

            dispatch(updateOrder({...updatedOrder, isUserReceiver: true, receiverId: null, receiver: null}))
        }

    }

    const chooseOtherReceiver = () => {
        shippingRecipient(false)

        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {

            dispatch(updateOrder({...updatedOrder, isUserReceiver: false, receiverId: null}))
        }
    }

    const updateOrderNpClientName = (state: boolean, tempId: number) => {

        let updatedOrder = orderObjects.find(o => o.tempId == tempId);
        if(updatedOrder) {
            if (state){
                dispatch(updateOrder({...updatedOrder, useNPShippingName: state}))
            } else {
                dispatch(updateOrder({...updatedOrder, useNPShippingName: state, usePaymentControl: false}))
            }

        }
    }

    const updatePaymentControl = (state: boolean, tempId: number) => {
        let updatedOrder = orderObjects.find(o => o.tempId == tempId);
        if(updatedOrder) {
            dispatch(updateOrder({...updatedOrder, usePaymentControl: state}))
        }
    }

    const productCountChange = (productId: number, count: number) => {
        let updatedOrder = orderObjects.find(o => o.tempId == currOrderState.tempId);
        if(updatedOrder) {
            updatedOrder = {...updatedOrder}
            updatedOrder.items = [...updatedOrder.items]
            const itemIndex = updatedOrder.items.map(i => i.productId).indexOf(productId)
            if(itemIndex != -1) {
                let item = updatedOrder.items[itemIndex]
                item = {...item, count: count};
                updatedOrder.items.splice(itemIndex, 1, item)
                dispatch(updateOrder(updatedOrder))
                const activeOrder = cart.map(item => item.orderId).indexOf(currOrderState.cartOrderId)

                dispatch(updateProductCountAsync(productId, activeOrder, count))
            }
        }
    }

    const removeProduct = (productId: number) => {

        const activeOrder = cart.map(item => item.orderId).indexOf(currOrderState.cartOrderId)

        cartServices.deleteCartProduct(currOrderState.cartOrderId, productId).then(data => {

            dispatch(removeFromCart({productId: productId, activeOrder: activeOrder}));

        })

    }

    return (
        <div className="order">
            <button
                className={isCheckedOrder && (!preOrderResult || preOrderResult?.result) ? "checkbox active" : "checkbox"}
                onClick={() => changeOrderState(currOrderState.tempId)}
            >
                <h2>{isReserve ? t('basket.reserve') : t('basket.order') + ' ' + (+numberOrder + 1)}</h2>
            </button>

            <div className="white-box">
                {preOrderResult && !(preOrderResult?.result) ? <Message className="error-sm" showIcon type="error">
                    <h3>{t('order.errorProductsPreOrder')}:</h3>

                    {preOrderResult ? <ul className="error-available-product"> {preOrderResult.missingItems.map((item, index) => {
                        let productName = currOrderState.items.find(i => i.productId == item.id )
                        return (
                            <li><div className="product-name">{productName ? productName.product.name : null}</div>
                                <div>{t('order.available')} <strong>{item.available}</strong></div>
                            </li>
                        )
                    })} </ul> : null
                    }


                </Message> : null}


                <div className={toggleShowProducts ? "order-details show" : "order-details"}>

                    <div>{currOrderState.items.length} {t('order.productsPrice')}:</div>
                    <div className="products-price-wrapper">

                        <span className="products-price_USD">{orderTotal.toFixed(2)}</span> {t(user.currency)}

                        {process.env.REACT_APP_LOCATION == 'ua'? <><span className="products-price_HRN">{orderTotalUah.toFixed(2)}</span> {t(user.rrpCurrency)}</>: null}
                    </div>
                    {preOrderResult?.missingItems.length !== 0 ? null :
                        <Button appearance="link"
                                onClick={isShowProducts}>{toggleShowProducts ? t('order.hide') : t('order.show')}</Button>}

                </div>

                {toggleShowProducts ? <div className="order-price-details-more">
                    {t('order.toPay')}: <span>{process.env.REACT_APP_LOCATION == 'ua'?orderTotalUah.toFixed(2): orderTotal.toFixed(2)}</span> {t(user.rrpCurrency)}
                </div> : null}

                {toggleShowProducts || !preOrderResult?.result ? <div className="order-list-details-more">
                    <CartOrderProducts
                        countButton={false}
                        missingItems={preOrderResult ? preOrderResult.missingItems : null}
                        order={{orderId:currOrderState.cartOrderId, orderItems: currOrderState.items}}
                        onProductCountChange={productCountChange}
                        removeProduct={removeProduct}
                    />
                </div> : null}

                <div className="contact-data-wrapper">

                    <div className="contact-data-body edit-mode">
                        <ContactData title={contactDataTitle} editContactData={editContactData} profile={false}/>
                    </div>

                </div>

                <div className="shipping_wrapper">

                    <OrderProductsShipping
                        order={currOrderState}
                        onSavedAddressChange={addSavedAddress}
                        onNewAddressChange={addNewDeliveryData}
                        onPaymentPickupDataChange={setPaymentAndPickupData}
                        errorsStateShipping={errorsStateShipping}
                        showPayOnDelivery={true}
                        showDeliveryPayer={true}
                        updateOrderNpClientName={updateOrderNpClientName}
                        updatePaymentControl={updatePaymentControl}
                    />

                    {/*<div className="delivery-pay-info">
                    {showDeliveryPayer != null && showDeliveryPayer == 0 ?
                        <div className="delivery-pay-info_payer">{t('profile.payer')}: {t('deliveryPayer.sender')}</div>
                        : null}

                    {showDeliveryPayer != null && showDeliveryPayer == 1 ?
                        <div className="delivery-pay-info_payer">{t('profile.payer')}: {t('deliveryPayer.receiver')}</div>
                        : null}

                    {showOrderAmount ? <div className="delivery-pay-info_sum">{t('profile.sum')}: {showOrderAmount} грн </div> : null }

                </div>*/}

                    {toggleShippingRecipient ? <div className="recipient-name-wrapper">
                        <div className="recipient-name">{user.lastName} {user.firstName}</div>
                        <div className="recipient-phone">{user.phone}</div>
                    </div> : null}

                    <ButtonToolbar className="shipping-recipient">
                        <Button size="lg" appearance="ghost" onClick={chooseUserReceiver}
                                active={toggleShippingRecipient ? true : false}>{t('order.recipientMe')}</Button>
                        <Button size="lg" appearance="ghost" onClick={chooseOtherReceiver}
                                active={toggleShippingRecipient ? false : true}>{t('order.recipientOther')}</Button>
                    </ButtonToolbar>

                    {errorStateReceiver ? null : <Message className="error-sm mt-15" showIcon type="error">
                        <p>{t('order.errorReceiver')}</p>
                    </Message>}

                    {toggleShippingRecipient ? null : <OrderRecipientList chosenRecipient={chosenRecipient} onNewRecipient={addNewReceiverData} />}

                    {/*{isAddNewReceiver ? <div className="recipient-name-wrapper">
                    <div className="recipient-name">{orderObject.receiver?.lastName} {orderObject.receiver?.firstName} {orderObject.receiver?.middleName}</div>
                    <div className="recipient-phone">{orderObject.receiver?.phone}</div>
                </div> : null}*/}

                    <Button appearance="link" onClick={addComment}
                            className="btn-add_comment">{t('order.addComment')}</Button>
                    {toggleAddComment ? <Input as="textarea" rows={3} placeholder={commentTxt} onChange={setComment} /> : null}


                </div>

            </div>

        </div>
    )
}

export default OrderProducts
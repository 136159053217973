import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Input} from "rsuite";
import PhoneInput from "../Form/PhoneInput/PhoneInput";
import {useAppSelector} from "../../hooks";
import SubscriptionModel from "../../interfaces/SubscriptionModel";
import subscriptionservices from "../../services/subscriptionservices";
import {SubscriptionType} from "../../enums/SubscriptionType";
import {useParams} from "react-router-dom";

const FormSubscribeModal: React.FC<{
    handleCloseSubscription: Function,
    setIsSubscribed: Function,
    disableNewProducts: boolean
    }> = ({handleCloseSubscription, setIsSubscribed, disableNewProducts}) => {
    const { t } = useTranslation();
    let {categoryId} = useParams();

    let {productId} = useParams();

    const filterInfo = useAppSelector(state => state.filters.value)
    const chosenFilters = useAppSelector(state => state.chosenFilters.value)
    const userPhone = useAppSelector(state => state.user.value.phone)
    const location = process.env.REACT_APP_LOCATION;

    const phoneText = t('isSubscribed.phoneViber')

    const [value, setValue] = useState(userPhone);
    const [errorVisible, setErrorVisible] = useState(false);

    const setPhoneValue = (phoneValue: string) => {
        let phone = value.replace(/[\(\)\-\+\_ ]/g, '');
        setValue(phoneValue)
        if((location == 'ua' && phone.length == 12) || (phone.length >= 7 && phone.length <= 15)){
            setErrorVisible(false)
        }
    }

    const [sType, setSType] = useState<SubscriptionType>(0)
    const changeType = (type: number) => {
        setSType(sType ^ type)
    }




    const subscribe = () => {

        let phone = value.replace(/[\(\)\-\+\_ ]/g, '');

        const data: SubscriptionModel = {
            type: sType,
            phone: phone,
            filters: {
                "fromPrice": filterInfo.priceFrom,
                "toPrice": filterInfo.priceTo,
                "sortOrder": 0,
                "id": productId ? +productId : null,
                "categoryId": categoryId ? +categoryId : null,
                "propertyFilters": chosenFilters,
                "customFilters": null,
                "page": 0,
                "itemsPerPage": 0,
            }
        }
        if((location == 'ua' && phone.length != 12) || !(phone.length >= 7 && phone.length <= 15)){
            setErrorVisible(true)
        } else {
            setErrorVisible(false)
            subscriptionservices.addSubscription(data).then(data => {
                setIsSubscribed(false)
        })
        }


    }

    return (
        <div className="form-subscribe-modal-wrapper">
            <Form fluid>
                <div className="input-wrapper">
                    <Form.Group>
                        <PhoneInput
                            valueFunction={setPhoneValue}
                            valuePhone={userPhone.substring(2)}
                            label={true}
                            showError={errorVisible}
                            labelText={phoneText} />
                    </Form.Group>
                </div>
                <div>
                    <label>
                        <input type={"checkbox"} disabled={disableNewProducts} onClick={() => changeType(SubscriptionType.NewProducts)} />{' '}
                        <span className={"checkbox"}>{t('isSubscribed.newProducts')}</span>
                    </label>
                </div>
                <div>
                    <label>
                        <input type={"checkbox"}  onClick={() => changeType(SubscriptionType.Availability)} />{' '}
                        <span className={"checkbox"}>{t('isSubscribed.presentsProducts')}</span>
                    </label>
                </div>
                <button type="submit" className="btn btn-lg" onClick={subscribe} >{t('subscribe')}</button>
            </Form>
        </div>
    )
}
export default FormSubscribeModal
import React, {useEffect, useState} from "react";
import GetOrderModel from "../../dto/order/GetOrderModel";
import {useTranslation} from "react-i18next";
import {ValueType} from "rsuite/Checkbox";
import {Checkbox, Table} from "rsuite";
import {NavLink} from "react-router-dom";
import GenericButton from "../Buttons/GenericButton";
import ShippingAddress from "../../interfaces/ShippingAddress";
import {CarrierType} from "../../enums/CarrierType";
import NewPost from "../../assets/img/new_post_sm.png";
import Meest from "../../assets/img/meest_sm.png";
import Delivery from "../../assets/img/delivery_sm.png";
import Ukrpost from "../../assets/img/ukrpost_sm.png";
import PickUp from "../../assets/img/pickup.svg";
import {useAppSelector} from "../../hooks";
const { Column, HeaderCell, Cell } = Table;

const CheckCell:React.FC<{ rowData?: {[key: string]: number}, onChange: any, checkedKeys: number[], dataKey: string}> = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
        <div>
            <Checkbox
                value={rowData![dataKey]}
                inline
                onChange={onChange}
                checked={checkedKeys.some(item => item === rowData![dataKey])}
            />
        </div>
    </Cell>
);

const CellOrderId:React.FC<{ rowData?: {[key: string]: number},dataKey: string}> = (
    {rowData, dataKey, ...props}) => (
    <Cell {...props}>
        <NavLink to={'/new-order/' + rowData![dataKey]} className={"name"}>{rowData![dataKey]}</NavLink>

    </Cell>
)

const CellDateTime:React.FC<{ rowData?: {[key: string]: number}, dataKey: string}> = (
    {rowData, dataKey,  ...props}) => (
    <Cell {...props}>
        <div className="date">{rowData![dataKey]}
            <span>{rowData!['deliveryTime']}</span>
        </div>
    </Cell>
)


const CellDelete:React.FC<{ rowData?: {[key: string]: number}, dataKey: string, removeCheckOnDelete: Function}> = ({rowData, dataKey, ...props}) => (
    <Cell {...props}>
        <GenericButton generic={"delete"} onClick={() => props.removeCheckOnDelete(rowData![dataKey])} isDisabled={false} showText={false} />
    </Cell>
)

const CellPrise:React.FC<{ rowData?: {[key: string]: number}, dataKey: string}> = ({rowData, dataKey, ...props}) => {
    const {t} = useTranslation();
    const currUser = useAppSelector((state) => state.user.value);
    return (
        <Cell {...props}>
            <span>{rowData![dataKey]} {t(currUser.currency)}</span>
        </Cell>
    )
}

const CellDelivery:React.FC<{rowData?: {[key: string]: any}, dataKey: string}> = ({rowData, dataKey, ...props}) => {
    const address = rowData![dataKey] as ShippingAddress;
    return (
        <Cell {...props}>
            {address ? <div className="logo">
                {address.carrierType == CarrierType.NewPost ? <img src={NewPost} alt="NewPost" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.MeestExpress ? <img src={Meest} alt="Meest" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.Delivery ? <img src={Delivery} alt="Meest" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.AddressDelivery ? <img src={PickUp} alt="Address Shipping" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.PickUp ? <img src={PickUp} alt="PickUp" width="24" height="24" />  : null}
            </div> : null}

            {address?.state ? <span>{address.state}</span> : null }
            {address?.district ? <span>, {address.district}</span> : null }
            {address?.city ? <span>, {address.city}</span> : null }
            {address?.carrierBranch ? <span>, {address.carrierBranch}</span> : null }
            {address?.street ? <span>, {address.street}</span> : null }
            {address?.house ? <span>, {address.house}</span> : null }
            {address?.apartment ? <span>, {address.apartment}</span> : null }
            {rowData![dataKey]?.carModel ? <span>, {rowData![dataKey].carModel}</span> : null }
            {rowData![dataKey]?.carNumber ? <span>, {rowData![dataKey].carNumber}</span> : null }
            {rowData![dataKey]?.deliveryDate ? <span>, {rowData![dataKey].deliveryDate}</span> : null }
            {rowData![dataKey]?.deliveryTime ? <span>, {rowData![dataKey].deliveryTime}</span> : null }
        </Cell>
    )
}

const MyReserveTable:React.FC<{data: GetOrderModel[],
    onOrderSelectionChange: (
        ids: number[]) => void,
    setButtonState?: (value: boolean) => void | null,
    deleteItem: Function}> = (
    {data, onOrderSelectionChange, setButtonState, deleteItem}
    ) => {
    const {t} = useTranslation();

    const [deviceHeight, setDeviceHeight] =  useState(window.innerHeight)

    useEffect(() => {
        let height = deviceHeight - 285
        setDeviceHeight(height)
    }, [])


    const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
    let checked = false;
    let indeterminate = false;

    if (checkedKeys.length === data.length) {
        checked = true;

    } else if (checkedKeys.length === 0) {
        checked = false;
    } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
    }


    useEffect(() => {

        onOrderSelectionChange(checkedKeys);

        if (setButtonState ){
            if (checkedKeys.length != 0){
                setButtonState(false)
            }
            else {
                setButtonState(true)
            }
        }

    }, [checkedKeys])

    const removeCheckOnDelete = (id: number) => {

        let checkedKeysCopy = [...checkedKeys]
        const index = checkedKeysCopy.indexOf(id)
        checkedKeysCopy.splice(index, 1)
        setCheckedKeys(checkedKeysCopy);

        deleteItem(id)
    }

    const handleCheckAll = (value: ValueType | undefined, checked: boolean) => {
        const keys = checked ? data.map(item => item.id) : [];
        setCheckedKeys(keys);

    };
    const handleCheck = (value:  ValueType | undefined, checked: boolean) => {
        const keys = checked ? [...checkedKeys, value as number] : checkedKeys.filter(item => item !== value);
        setCheckedKeys(keys);
    };
    return (
        <div className="my-orders-table-wrapper">
            <div className="table-responsive">
                <Table height={deviceHeight} data={data} id="table" wordWrap="break-word" >
                    <Column width={50} align="center" verticalAlign="middle" >
                        <HeaderCell style={{ padding: 0 }}>
                            <div>
                                <Checkbox
                                    inline
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onChange={handleCheckAll}
                                />
                            </div>
                        </HeaderCell>
                        <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />

                    </Column>

                    <Column width={110} verticalAlign="middle"  >
                        <HeaderCell>{t('tableHeader.orderId')}</HeaderCell>
                        <CellOrderId dataKey="id" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle"  >
                        <HeaderCell>{t('tableHeader.orderDate')}</HeaderCell>
                        <CellDateTime dataKey="orderDate" />
                    </Column>


                    <Column flexGrow={1} verticalAlign="middle"  >
                        <HeaderCell>{t('tableHeader.orderSum')}</HeaderCell>
                        <CellPrise dataKey="totalAmountUSD" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle"  >
                        <HeaderCell>{t('tableHeader.income')}</HeaderCell>
                        <CellPrise dataKey="totalProfitUSD" />
                    </Column>

                    <Column flexGrow={3} verticalAlign="middle"  >
                        <HeaderCell>{t('tableHeader.delivery')}</HeaderCell>
                        <CellDelivery dataKey="deliveryAddress" />
                    </Column>

                    <Column width={50} verticalAlign="middle"  >
                        <HeaderCell>#</HeaderCell>
                        <CellDelete dataKey="id" removeCheckOnDelete={removeCheckOnDelete} />
                    </Column>

                </Table>
            </div>
        </div>
    )

}

export default MyReserveTable
import React from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks";

import './ExchangeRates.sass'



const ExchangeRates = () => {
    const { t } = useTranslation();
    const rate = useAppSelector<number>(state => state.rate.value)
    const balance = useAppSelector<number>(state => state.user.value.balance)

    return (
        <div className={"exchange_rate-wrapper"}>
            <div className={"balance_wrapper"}>{t('balance')} <span className={balance > 0 ? "red" : "green"}> {Number(-balance).toFixed(2)} {t('USD')}</span></div>
            <div className={"rate_wrapper"}>{t('exchangeRates')} <span>$ {rate}</span></div>
        </div>

    )
}
export default ExchangeRates;
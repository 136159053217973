import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Modal, Schema, Toggle} from "rsuite";

import PhoneInput from "../../Form/PhoneInput/PhoneInput";

import {ManagerProfile} from "../../../interfaces/ManagerProfile";
import clientManagerService from "../../../services/clientManagerService";

import "./ProfileMyManagerModal.sass"

function TextField(props: any) {
    const { fieldName, label, value, setValue, accepter, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                accepter={accepter} {...rest}
            />
        </Form.Group>
    );
}

const ProfileMyManagerModal: React.FC<{editManagerData: ManagerProfile | null, openModal: boolean, handleCloseManagerModal: Function}> = (
    {editManagerData, openModal, handleCloseManagerModal}) => {
    const { t } = useTranslation();
    const [formError, setFormError] = useState({});

    const [errorPhoneVisible, setErrorPhoneVisible] = useState(false);
    const errorField = t('form.requiredFieldMsg')
    const errorEmail = t('form.validEmailAddress')

    const firstName = t('form.firstName')

    let managerModel: ManagerProfile = {
        name: '',
        phone: '',
        permissions: [],
        email: '',
        id: 0
    }

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        firstName: StringType().isRequired(errorField),
        email: StringType()
            .isEmail(errorEmail)
            .isRequired(errorField)
    });


    let phoneMask = editManagerData?.phone.substring(2);

    const [managerName, setContactDataName] = useState(editManagerData?.name)
    const [managerPhone, setManagerPhone] = useState(phoneMask)
    const [managerEmail, setManagerEmail] = useState(editManagerData?.email)
    const [managerPermission, setManagerPermission] = useState<string[]>(editManagerData?.permissions?? [])
    const location = process.env.REACT_APP_LOCATION;

    const updateMenegerPhone = (phoneValue: string) => {
        let phone = phoneValue.replace(/[\(\)\-\+\_ ]/g, '');
        if((location == 'ua' && phone.length != 12) || !(phone.length >= 7 && phone.length <= 15)){
            setErrorPhoneVisible(true)
        } else {
            setErrorPhoneVisible(false)
        }
        if (phoneValue) setManagerPhone(phoneValue)
    }
    const [formValue, setFormValue] = useState({
        firstName: managerName,
        email: managerEmail
    });


    const [isOrders, setIsOrders] = useState(false)

    const toggleOrders = (checked: boolean, event: any) => {
        setIsOrders(checked => !checked)
        let newPermission = [...managerPermission]
        let notifications = ["NotifyManagerOrderOnly", "NotifyOrderHistory", "NotifyPrices"]
        notifications.forEach((permission: string) => {

            let index = newPermission.indexOf(permission)
            if (index > -1) {
                newPermission.splice(index, 1)

            }
        })

        setManagerPermission([...newPermission])
    }

    const hasPermission = (permission: string): boolean => {
        return managerPermission.indexOf(permission) != -1
    }

    const [isManagerOrders, setIsManagerOrders] = useState(false)
    const [isPrices, setPrices] = useState(false)
    const [isOrderHistory, setIsOrderHistory] = useState(false)

    useEffect(() => {
        const isManagerOrdersValue = editManagerData != null && editManagerData?.permissions.indexOf('NotifyManagerOrderOnly') > -1
        const pricesValue = editManagerData != null && editManagerData?.permissions.indexOf('NotifyPrices') > -1
        const isOrderHistoryValue = editManagerData != null && editManagerData?.permissions.indexOf('NotifyOrderHistory') > -1
        setIsManagerOrders(isManagerOrdersValue)
        setPrices(pricesValue)
        setIsOrderHistory(isOrderHistoryValue)
        setIsOrders(isManagerOrdersValue || pricesValue || isOrderHistoryValue)
    }, [editManagerData])

    const addPermission = (checkboxState: boolean, permissionName: string, setCheckboxState: ((f: (current: boolean) => boolean) => void) | null ) => {
        if (!checkboxState){
            setManagerPermission((value) => [...value, permissionName])
        } else {
            let newPermission = [...managerPermission]
            let index = newPermission.indexOf(permissionName)
            if (index > -1) {
                newPermission.splice(index, 1)
                setManagerPermission([...newPermission])
            }
        }
        if (setCheckboxState) {
            setCheckboxState(current => !current)
        }
    }


    const addManager = () => {

        if(!managerPhone) setErrorPhoneVisible(true)

        if (Object.keys(formError).length == 0 && managerName && managerPhone && managerEmail){
            managerModel.name = managerName
            managerModel.email = managerEmail
            managerModel.permissions = managerPermission
            let phone = managerPhone.replace(/[\(\)\-\+\_ ]/g, '');
            managerModel.phone = phone


            if (editManagerData !== null) {
                managerModel.id = editManagerData.id
                clientManagerService.editClientManager(editManagerData.id, managerModel).then(() => {
                    let managerModel: ManagerProfile = {
                        name: managerName,
                        email: managerEmail,
                        phone: phone,
                        permissions: managerPermission,
                        id: editManagerData.id}
                    handleCloseManagerModal(managerModel, false)
                } )
            } else {
                clientManagerService.addClientManager(managerModel).then((data) => {
                    let managerModel: ManagerProfile = {
                        name: managerName,
                        email: managerEmail,
                        permissions: managerPermission,
                        phone: phone,
                        id: data}
                    handleCloseManagerModal(managerModel, true)

                })
            }

        }
    }

    return (
        <Modal
            open={openModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            onClose={() => handleCloseManagerModal()}
            className="modal-manager">
            <Modal.Header>
                <Modal.Title id="modal-title">{t('myManager.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-description">

                <Form model={model} onCheck={setFormError} formDefaultValue={formValue} onSubmit={addManager}>
                    <div className={formError.hasOwnProperty('firstName') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField fieldName="firstName" label={firstName} value={managerName} setValue={setContactDataName} key={'firstName'} />
                    </div>
                    <div className={errorPhoneVisible ? "input-wrapper has-error" : "input-wrapper"}>
                        <label>{t('form.phone')}</label>
                        <Form.Group controlId={'contact-data_phone'}>
                            <PhoneInput valueFunction={updateMenegerPhone} valuePhone={managerPhone} showError={errorPhoneVisible} />
                        </Form.Group>
                    </div>
                    <div className={formError.hasOwnProperty('email') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField name="email" label={'E-mail'} key={'email'} value={managerEmail} setValue={setManagerEmail} />
                    </div>
                    <div className="title_permission">{t('myManager.titlePermission')}:</div>
                    <div className="order-status-state">
                        <Toggle checked={isOrders} className="comparison-difference" onChange={toggleOrders} />
                        {t('profile.sendStatusOrder')}
                    </div>

                    {isOrders ? <div>
                        <div className="checkbox-wrapper">
                            <div
                                className={isManagerOrders ? "checkbox active" : "checkbox"}
                                onClick={() => addPermission(isManagerOrders, "NotifyManagerOrderOnly", setIsManagerOrders)}
                            >
                                <span className={"value"}>
                                    {t('myManager.managerOrders')}
                                </span>
                            </div>
                        </div>
                        <div className="checkbox-wrapper">
                            <div
                                className={isOrderHistory ? "checkbox active" : "checkbox"}
                                onClick={() => addPermission(isOrderHistory, "NotifyOrderHistory", setIsOrderHistory)}
                            >
                                <span className={"value"}>
                                    {t('myManager.orderHistory')}
                                </span>
                            </div>
                        </div>
                        <div className="checkbox-wrapper">
                            <div
                                className={isPrices ? "checkbox active" : "checkbox"}
                                onClick={() => addPermission(isPrices, "NotifyPrices", setPrices)}
                            >
                                <span className={"value"}>
                                    {t('myManager.prices')}
                                </span>
                            </div>
                        </div>
                    </div> : null}

                    <div className="order-status-state">
                        <Toggle checked={hasPermission('ViewTransactions')} className="comparison-difference" onChange={(checked: boolean, event) =>  addPermission(!checked, "ViewTransactions", null)} />
                        {t('myManager.count')}
                    </div>
                    <div className="order-status-state">
                        <Toggle checked={hasPermission('ViewReclamations')} className="comparison-difference"  onChange={(checked: boolean, event) =>  addPermission(!checked, "ViewReclamations", null)} />
                        {t('myManager.add')}
                    </div>
                    <div className="order-status-state">
                        <Toggle checked={hasPermission('ViewReserves')} className="comparison-difference"  onChange={(checked: boolean, event) =>  addPermission(!checked, "ViewReserves", null)} />
                        {t('myManager.reserve')}
                    </div>
                    <div className="order-status-state">
                        <Toggle checked={hasPermission('ViewSubscriptions')} className="comparison-difference"  onChange={(checked: boolean, event) =>  addPermission(!checked, "ViewSubscriptions", null)} />
                        {t('myManager.subscription')}
                    </div>
                    <button type="submit" className="btn btn-lg">{t('btn.save')}</button>

                </Form>

            </Modal.Body>
        </Modal>
    )
}
export default ProfileMyManagerModal
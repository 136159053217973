import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialVal: string = process.env.REACT_APP_LOCATION === 'ua' ? 'uk' : 'pl';

export const currentLangSlice = createSlice({
    name: 'currentLang',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setCurrentLanguage: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        },
    }
});
export const { setCurrentLanguage } = currentLangSlice.actions;

export default currentLangSlice.reducer
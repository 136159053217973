import React, {useEffect, useState} from "react";

import {Col, FlexboxGrid, Row} from "rsuite";
import {NavLink} from "react-router-dom";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {useTranslation} from "react-i18next";
import NoImage from "../../../assets/img/no-img_small.svg";

import TransactionsItemModel from "../../../dto/transactions/TransactionsItemModel";

import "./TransactionsTableProducts.sass"
import {useAppSelector} from "../../../hooks";

const columnHelper = createColumnHelper<TransactionsItemModel>();

const TransactionsTableProducts:React.FC<{data: TransactionsItemModel[]}>  = ({data}) => {
    const { t } = useTranslation();
    const wsRate = useAppSelector<number>((state) => state.wsRate.value);
    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)
    const [isMobileView, setIsMobileView] = useState(false)
    const currUser = useAppSelector((state) => state.user.value);
    useEffect(() => {
        if(deviceWidth <= 1024) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    const columnsCell = [
        columnHelper.accessor('vendorCode', {
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.vendorCode')}</span>,
        }),
        columnHelper.accessor(row => row.photoUrl, {
            id: 'url',
            cell: info => <img src={info.getValue() == null ? NoImage : info.getValue()} width={"38px"} />,
            header: () => <span>{t('productsTableHeader.url')}</span>,
        }),
        columnHelper.accessor(row => row.productName, {
            id: 'name',
            cell: info => <NavLink to={'/product/' + info.row.original.productId} className={"name"}>{info.getValue()}</NavLink>,
            header: () => <span>{t('productsTableHeader.name')}</span>,
        }),
        columnHelper.accessor(row => row.lastBoughtOn, {
            id: 'lastPurchase',
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.lastPurchase')}</span>,
        }),
        columnHelper.accessor(row => row.recommendedRetailPrice, {
            id: 'rrc',
            cell: info => <span>{Math.round(info.getValue() * wsRate)} {t(currUser.rrpCurrency)}</span>,
            header: () => <span>{t('productsTableHeader.rrc')}</span>,
        }),
        columnHelper.accessor(row => row.price, {
            id: 'price',
            cell: info => <span>{Number(info.getValue()).toFixed(2)} {t(currUser.currency)}</span>,
            header: () => <span>{t('productsTableHeader.price')}</span>,
        }),
        columnHelper.accessor(row => row.left, {
            id: 'left',
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.left')}</span>,
        }),
        /*columnHelper.accessor(row => row.toReserve, {
            id: 'toReserve',
            cell: info => <ProductCountTable buttonName={t('add')} appointment={"reserve"} product={info.row.original}></ProductCountTable>,
            header: () => <span>{t('productsTableHeader.toReserve')}</span>,

        }),*/
        /*columnHelper.accessor(row => row.toCart, {
            id: 'toCart',
            cell: info => <ProductCountTable buttonName={t('add')} appointment={"cart"} product={info.row.original}></ProductCountTable>,
            header: () => <span>{t('productsTableHeader.toCart')}</span>,
        }),*/
        columnHelper.accessor(row => row.price, {
            id: 'totalPrice',
            cell: info => <span>{Number(info.getValue()).toFixed(2)} {t(currUser.currency)}</span>,
            header: () => <span>{t('productsTableHeader.totalPrice')}</span>,
        }),
        columnHelper.accessor(row => row.profit, {
            id: 'income',
            cell: info => <span>{Number(info.getValue()).toFixed(2)} {t(currUser.currency)}</span>,
            header: () => <span>{t('productsTableHeader.income')}</span>,
        }),
        /*columnHelper.accessor(row => row.compare, {
            id: 'compareHeader',
            cell: info => <AddToComare chosenProduct={info.row.original} value={info.getValue()}></AddToComare>,
            header: () => <span></span>,
        }),*/
    ]

    const [columns] = useState<typeof columnsCell>(() => [
        ...columnsCell,
    ])

    const [columnVisibility, setColumnVisibility] = useState({})


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    })

    return (
        <div>
            {isMobileView ? <div className={"table-mobile-view"}>
                {data.map((product, index) =>
                    <div className={"product-item"} key={index}>
                        <FlexboxGrid className={"product-info"}>
                            <FlexboxGrid.Item className={"product-info-vendorCode"}>
                                <div className="value vendorCode">{product.vendorCode}</div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="product-info-image">
                                <div className="value">
                                    <img src={product.photoUrl != null ? product.photoUrl : NoImage} alt=""/>
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="product-info-name">
                                <div className="value">
                                    <NavLink to={'/product/' + product.id}>{product.productName}</NavLink>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <Row className={"product-info"}>
                            <Col xs={5}>
                                <div className="title">{t('productsTableHeader.rrc')}</div>
                                <div className="value bold">{product.recommendedRetailPrice} {t(currUser.currency)}</div>
                            </Col>
                            <Col xs={5}>
                                <div className="title">{t('productsTableHeader.price')}</div>
                                <div className="value bold">{product.price} {t(currUser.currency)}</div>
                            </Col>
                            <Col xs={5}>
                                <div className="title">В заказе</div>
                                <div className="value bold">{product.count}</div>
                            </Col>
                            <Col xs={5}>
                                <FlexboxGrid.Item >
                                    <div className="title">{t('productsTableHeader.totalPrice')}</div>
                                    <div className="value bold">
                                        {Number((product.price).toFixed(2))} {t(currUser.currency)}
                                    </div>
                                </FlexboxGrid.Item>
                            </Col>
                            <Col xs={5}>
                                <FlexboxGrid.Item>
                                    <div className="title">{t('productsTableHeader.income')}</div>
                                    <div className="value bold">{Number(product.profit).toFixed(2)}</div>
                                </FlexboxGrid.Item>
                            </Col>
                        </Row>
                    </div>
                )}

            </div> : <div className={"table-responsive"}>
                <table>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th className={header.id} key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className={cell.column.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export default TransactionsTableProducts
import React, {useEffect, useState} from "react";
import {Button, ButtonToolbar, Container, Content, FlexboxGrid, Form, Input, MaskedInput, Panel} from "rsuite";
import { useNavigate } from "react-router-dom";

import Velotrade from "../../assets/img/Velotrade.svg";

import "./Login.sass"
import {useTranslation} from "react-i18next";
import ReactCodeInput from "react-code-input";
import authService from "../../services/authService";
import PhoneInput from "../../components/Form/PhoneInput/PhoneInput";
import {setUser} from "../../slices/userSlice";
import {useAppDispatch} from "../../hooks";
import User from "../../interfaces/User";
import {setCompareList} from "../../slices/compareSlice";
import {setCart} from "../../slices/cartSlice";
/*import {CartOrder, CartProduct} from "../../interfaces/CartProduct";
import Product from "../../interfaces/Product/Product";*/
import {setRate} from "../../slices/rateSlice";
/*import store from "../../store";*/
import {setWSRate} from "../../slices/wsRateSlice";
import LoginWithEmail from "./components/LoginWithEmail";

const Login: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    /*const options = [
        {
            name: 'US phone number',
            mask: ['+', '3', '8','(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+38(___) ___-__-__'
        },
    ]

    const [option, setOption] = React.useState(options[0]);*/
    const [value, setValue] = useState('');
    const [guide, setGuide] = React.useState(true);
    const [keepCharPositions, setKeepCharPositions] = React.useState(true);
    const [placeholderChar, setPlaceholderChar] = React.useState('_');
    const [showMask, setShowMask] = React.useState(false);
    const [location, setLocation] = useState('');

    const [isCode, setIsCode] = useState(false)

    const [errorVisible, setErrorVisible] = useState(false);

    const setPhoneValue = (phoneValue: string) => {
        let phone = value.replace(/[\(\)\-\+\_ ]/g, '');
        setValue(phoneValue)
        if((process.env.REACT_APP_LOCATION == 'ua' && phone.length != 12) || !(phone.length >= 7 && phone.length <= 15)){
            setErrorVisible(false)
        }
    }

    const [phoneNotFound, setPhoneNotFound] = useState(false)
    const [loginError, setloginError] = useState('')
    const [isSendByEmail, setIsSendByEmail] = useState(false)

    const sendPhone = () => {
        setCodeError(false)
        setloginError('')
        setPhoneNotFound(false)
        let phone = value.replace(/[\(\)\-\+\_ ]/g, '');
        if((process.env.REACT_APP_LOCATION == 'ua' && phone.length != 12) || !(phone.length >= 7 && phone.length <= 15)){
            setErrorVisible(true)
        } else {
            setErrorVisible(false)
            authService.sendCode(value, "viber", location)
                .then(data => {
                    data ? setIsCode(true) : setIsCode(false)

                    if(data.sentViaViber){
                        setIsSendByEmail(false)
                    }
                    else {
                        setIsSendByEmail(true)
                    }


                }).catch((error: Error) => {
                setloginError(error.message)
                setPhoneNotFound(true)
            })
        }


    }

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false)

    const codeChange = (value: string) => {

        setCode(value)

        if(value.length == 6){
            sendCode(value)
        }

    }

    const sendCode = (userCode: string) => {
        setloginError('')

        authService.login(value, userCode, location)
            .then(data => {
                const cart = data.user.cartOrders.map((order) => {
                    return {
                        orderId: order.id,
                        orderItems: order.items.map((item) => {
                            return {
                                product: {
                                    id: item.productId,
                                    vendorCode: item.vendorCode,
                                    photoUrl: '',
                                    name: item.name,
                                    lastPurchaseDate: '',
                                    available: 0,
                                    recommendedRetailPrice: item.recommendedRetailPrice,
                                    price: item.price,
                                    lastPurchaseCount: 0,
                                    categoryId: 0
                                },
                                count: item.count
                            }
                        })
                    }

                })

                dispatch(setUser(data.user as User))
                dispatch(setWSRate(data.user.exchangeRateWS))
                dispatch(setCompareList(data.user.comparisonItems))
                dispatch(setRate(data.user.exchangeRate))
                dispatch(setCart(cart))
                navigate("/");
            }).catch((error: Error) => {
            setloginError(error.message)
            setCodeError(true)
        })
    }

    const [isLoginByEmail, setLoginByEmail] = useState(false)

    useEffect(() => {
        if (process.env.REACT_APP_LOCATION === 'pl'){
            setLoginByEmail(true)
            setLocation('pl')
        }
    }, [])

    const [loginWithEmailError, setloginWithEmailError] = useState('');
    const [isLoginWithEmailError, setLoginWithEmailError] = useState(false)

    const submitFormWithEmail = (userEmail: string) => {

        setValue(userEmail)

        authService.sendCode(userEmail, "viber", location)
            .then(data => {
                data ? setIsCode(true) : setIsCode(false)

                if(data.sentViaViber){
                    setIsSendByEmail(false)
                }
                else {
                    setIsSendByEmail(true)
                }


            }).catch((error: Error) => {
            setloginWithEmailError(error.message)
            setLoginWithEmailError(true)
        })
    }

    const [contactDataPhone, setContactDataPhone] = useState('')
    useEffect(() => {
        /* let phone = value.replace(/[\(\)\-\+\_ ]/g, '');
         if(phone.length != 12){
             setErrorVisible(true)
         } else {
             setErrorVisible(false)
         }*/
    }, [value])

    return (
        <div className="login-page">

            <FlexboxGrid>
                <FlexboxGrid.Item colspan={12} className={"login-form-wrapper"}>
                    <div className={"header"}>
                        <header>
                            <a href="/" className={"logo"}>
                                <img src={Velotrade} alt="Velotrade"/>
                            </a>
                        </header>
                    </div>
                    <FlexboxGrid className={"login-form"}>
                        <FlexboxGrid.Item className="login-form-block" colspan={12}>
                            <Panel header={<h3>{t('login.header')}</h3>}>
                                {isCode ? <Form.Group>
                                    {isSendByEmail ? <Form.ControlLabel className="login-subheaderEnterCode">{t('login.subheaderEmail')} <strong>Email</strong></Form.ControlLabel>
                                        : <Form.ControlLabel className="login-subheaderEnterCode">{t('login.subheaderEnterCode')}</Form.ControlLabel>}

                                    {isSendByEmail ? null : <div>
                                        <span>{value}</span>
                                        <button className={"btn-usual"}
                                                onClick={() => setIsCode(false)}>{t('login.change')}</button>
                                    </div>
                                    }

                                    <div className="enter-code-wrapper">
                                        <ReactCodeInput onChange={(value) => codeChange(value)} type='number' fields={6} name="code" inputMode="numeric" />
                                    </div>
                                    {codeError ? <div className="error-msg">{t('login.errorCode')}</div> : null}


                                    <Button className="btn btn-lg" onClick={() => sendCode(code)} appearance="primary">{t('login.submit')}</Button>
                                    <div>
                                        <span className="login-codeFailed">{t('login.codeFailed')}</span>
                                        <button
                                            onClick={sendPhone}
                                            type="submit"
                                            className={"btn-usual"}>
                                            {t('login.sendAgain')}
                                        </button>
                                    </div>

                                </Form.Group> : isLoginByEmail ? <LoginWithEmail
                                    submitForm={submitFormWithEmail}
                                    loginError={loginWithEmailError}
                                    emailNotFound={isLoginWithEmailError}
                                /> : <Form fluid>
                                    <Form.Group className={errorVisible ? 'not-valid' : 'valid'}>
                                        <Form.ControlLabel className="login-subheader">{t('login.subheader')}</Form.ControlLabel>
                                        <PhoneInput valueFunction={setPhoneValue} label={true} />
                                        {errorVisible ? <div className="error-msg">{t('form.requiredFieldMsg')}</div> : null}
                                        {phoneNotFound ? <div className="error-msg">{loginError}</div> : null}
                                        <button
                                            type="submit"
                                            className="btn btn-lg"
                                            onClick={sendPhone}
                                        >
                                            {t('login.submit')}
                                        </button>
                                    </Form.Group>
                                </Form>}

                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>


                <FlexboxGrid.Item colspan={12} className={"login-bg"}>
                </FlexboxGrid.Item>

            </FlexboxGrid>





        </div>
    )
}
export default Login